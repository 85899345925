import {EMPTY} from "../../../../app/const/appConst";
import {getAllRecordDataById} from "../../../../service/dictionaryService";
import {isEmptyOrNull} from "../../../../app/helper/commonHelper";
import {get} from "lodash";
import {BOOLEAN, DICTIONARY, DICTIONARY_SET, ENUM, ENUM_SET} from "../../form/helper/formConstants";
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {getDefaultValueForType} from "../../form/helper/formHelper";

function useDictionaryElementView(metadata, token, t, params) {
    const location = useLocation();
    const ORDER_FIELD_PROPERTY = 'order';
    const [dictionaryItem, setDictionaryItem] = useState({});
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);

    /**
     * Получение значения элемента справочника с бэка
     */
    function getItemInfo() {
        setIsLoading(true);
        const response = getAllRecordDataById(Number(params.id), metadata.backControllerName, token);
        response.then((resp) => {
            const data = resp.data.data;
            setDictionaryItem(data);
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        })
        setIsLoading(false);
    }

    /**
     * Фильтрация и сортировка полей справочника.
     *
     * В качестве сортировки используем order из metadata
     * !visibleField не показываем
     */
    function filterAndSortFields(metadata) {
        return Object.entries(metadata.fields)
            .filter(([, value]) => value.hasOwnProperty(ORDER_FIELD_PROPERTY) && value.visibleField !== false)
            .sort(([, a], [, b]) => a.order - b.order)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    }

    /**
     * Формирование итогового вида по элементу справочника
     */
    function formInfoMap() {
        const infoMap = [];
        if (isEmptyOrNull(metadata) || isEmptyOrNull(dictionaryItem)) {
            return;
        }
        Object.entries(filterAndSortFields(metadata)).forEach(field => {
            const dictionaryValue = getFieldValue(field[1].type, field[1].objectPath, dictionaryItem);
            if (isEmptyOrNull(dictionaryValue)) {
                return;
            }
            infoMap.push({
                title: t(field[1].placeholder),
                value: dictionaryValue,
                hasValue: true
            });
        })
        return infoMap;
    }

    /**
     * Получение форматированного значения поля в зависимости от его типа из metadata
     */
    function getFieldValue(fieldType, objectPath, dictionaryItem) {
        const value = get(dictionaryItem, objectPath, getDefaultValueForType(fieldType));
        switch (fieldType) {
            case BOOLEAN:
                return value === true ? t('common.yes') : t('common.no')
            case ENUM_SET:
                return isEmptyOrNull(value) ? value :
                    value.map(el => `— ${el.name}`).join('\n');
            case ENUM:
                return isEmptyOrNull(value) ? value : value.name;
            case DICTIONARY:
                return isEmptyOrNull(value) ? value : getDictionaryLink(value, objectPath);
            case DICTIONARY_SET:
                return isEmptyOrNull(value) ?
                    value :
                    [...value].sort((a, b) => a.name.localeCompare(b.name)).map(el => getDictionaryLink(el, objectPath));

            default:
                return value;
        }
    }

    /**
     * Формируем ссылку на элемент связанного справочника
     */
    function getDictionaryLink(linkedDictionaryItem, objectPath) {
        // return linkedDictionaryItem.name;
        return <span key={linkedDictionaryItem.id}>
            <Link to={`/${metadata.fields[objectPath].optionsSource}${linkedDictionaryItem.id}`}
                  className="goto__link">{linkedDictionaryItem.name}</Link>
            <br/>
        </span>
    }

    /**
     * Получение элемента справочника при загрузке страницы
     */
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        getItemInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Получение элемента справочника при изменении пути в адресной строке
     */
    useEffect(() => {
        getItemInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    /**
     * Формирование метамодели элемента справочника для дальнейшей отрисовки
     */
    useEffect(() => {
        if (isEmptyOrNull(dictionaryItem)) {
            return;
        }
        formInfoMap(metadata, dictionaryItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dictionaryItem]);

    return {
        errorMessage,
        loading,
        dictionaryItem,
        formInfoMap
    }
}

export default useDictionaryElementView;