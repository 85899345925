import React from 'react';
import {EMPTY} from "../../../../app/const/appConst";
import Content from "../../Content";
import {hasValue} from "../../../../app/helper/commonHelper";
import TableNoResults from "../../table/TableNoResults";
import Spinner from "../../Spinner";
import {get} from "lodash";
import InformationBlock from "../../ui/text/informationblock/InformationBlock";
import DictionaryHeader from "../DictionaryHeader";
import useDictionaryElementView from "./useDictionaryElementView";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

/**
 * Компонент просмотра элемента справочника
 */
const DictionaryElementView = ({metadata}) => {
    const params = useParams();
    const token = useSelector(state => state.userReducer.token);
    const {t} = useTranslation();
    const {
        errorMessage,
        loading,
        dictionaryItem,
        formInfoMap
    } = useDictionaryElementView(metadata, token, t, params);

    if (hasValue(errorMessage)) {
        return (<Content>
            <TableNoResults title={errorMessage}/>
        </Content>);
    }

    return (
        <Content>
            {loading ? <Spinner/> :
                <>
                    <DictionaryHeader topInscription={{
                        title: t(metadata.dictionaryTitle),
                        allowLinkForAllUsers: metadata.isAllElementsPageAvailable,
                        path: metadata.backControllerName
                    }}
                                      mainInscription={get(dictionaryItem, metadata.fields.name.objectPath, EMPTY)}
                                      showEditAction={{doShow: true, path: metadata.backControllerName}}
                                      dictionaryItem={dictionaryItem}/>
                    <InformationBlock infoMap={formInfoMap()} withBorders={false}/>
                </>
            }
        </Content>
    );
};

export default DictionaryElementView;