//список всех доступных справочников
import {ANIMAL_CLUBS_METADATA} from "./metadata/animalClubMetadata";
import {DISEASE_SECTIONS_METADATA} from "./metadata/diseaseSectionsMetadata";
import {DISEASE_METADATA} from "./metadata/diseaseMetadata";
import {BREED_DISEASE_METADATA} from "./metadata/breedDiseaseMetadata";
import {CONTINENT_METADATA} from "./metadata/continentMetadata";
import {COUNTRY_METADATA} from "./metadata/countryMetadata";

export const Dictionarylink = [
    {title: CONTINENT_METADATA.dictionaryTitle, path: `/${CONTINENT_METADATA.backControllerName}`},
    {title: COUNTRY_METADATA.dictionaryTitle, path: `/${COUNTRY_METADATA.backControllerName}`},
    {title: ANIMAL_CLUBS_METADATA.dictionaryTitle, path: `/${ANIMAL_CLUBS_METADATA.backControllerName}`},
    {title: DISEASE_SECTIONS_METADATA.dictionaryTitle, path: `/${DISEASE_SECTIONS_METADATA.backControllerName}`},
    {title: DISEASE_METADATA.dictionaryTitle, path: `/${DISEASE_METADATA.backControllerName}`},
    {title: BREED_DISEASE_METADATA.dictionaryTitle, path: `/${BREED_DISEASE_METADATA.backControllerName}`}
]