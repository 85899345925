import React, {useEffect, useState} from 'react';
import {CHECKBOX_CLASS, ERROR_CLASS} from "../../../../../../utils/stringutils";
import {storeItem, stringToBoolean} from "../../../helper/formHelper";
import FormItem from "./FormItem";
import {EMPTY} from "../../../../../../app/const/appConst";

/**
 * Чекбокс
 */
const CheckboxFormItem = ({
                              fieldName, register, title, tabIndex = null, errors,
                              checkboxValue = false, putValueInStore = false,
                              additionalStyle = ''
                          }) => {

    const [value, setValue] = useState(stringToBoolean(checkboxValue));
    const [checkboxClass, setCheckboxClass] = useState(EMPTY)

    useEffect(() => {
        setCheckboxClass(`${CHECKBOX_CLASS} ${additionalStyle}`)
        setValue(stringToBoolean(checkboxValue));
    }, [additionalStyle, checkboxValue])



    function toggleValue() {
        const newValue = !value
            setValue(newValue);

        if (putValueInStore) {
            storeItem(fieldName, newValue)
        }
    }

    return (
        <FormItem>
            <div className={errors[fieldName] ? `${checkboxClass} ${ERROR_CLASS}` : `${checkboxClass}`}>
                <input type="checkbox"
                       className="checkbox__input"
                       id={fieldName}
                       checked={value}
                       {...register(fieldName)}
                       onClick={() => toggleValue()}
                       tabIndex={tabIndex}/>
                <label htmlFor={fieldName} className="checkbox__label">
                    <span>{title}</span>
                </label>
            </div>
        </FormItem>
    );
};

export default CheckboxFormItem;