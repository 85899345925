import React from 'react';

/**
 * Стрелка, которая меняет свое направление при нажатии.
 * Используется для сокрытия/отображения сворачиваемых блоков
 */
const Arrow = ({isActive}) => {
    return (
        <span className={isActive ? "arrow active" : "arrow"}>
                    <span></span>
                    <span></span>
                </span>
    );
};

export default Arrow;