import {
    DICTIONARY_SET,
    FORM_INPUT,
    FORM_TEXTAREA,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const DISEASE_SECTIONS_ID = "diseaseSectionsId";
export const DISEASE_SECTIONS_TRANSLATION_ID = "diseaseSectionsTranslationId";
export const DISEASE_SECTIONS_CODE = "diseaseSectionsCode";
export const DISEASE_SECTIONS_NAME = "diseaseSectionsName";
export const DISEASE_SECTIONS_DESCRIPTION = "diseaseSectionsDescription";
export const DISEASE_SECTIONS_LOCALE = "diseaseSectionsLocale";
export const DISEASE_SECTIONS_DISEASES = "diseaseSectionsDiseases";

export const DISEASE_SECTIONS_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.diseaseSection.title',
    backControllerName: 'disease-sections',
    titlePrepositionalCase: 'adminPanel.dictionaries.diseaseSection.titlePrepositionalCase',
    fields: {
        id: {
            title: DISEASE_SECTIONS_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: DISEASE_SECTIONS_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: DISEASE_SECTIONS_NAME,
            placeholder: 'adminPanel.dictionaries.diseaseSection.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: DISEASE_SECTIONS_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'DISEASE_SECTIONS_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        description: {
            title: DISEASE_SECTIONS_DESCRIPTION,
            placeholder: 'adminPanel.dictionaries.diseaseSection.description',
            objectPath: 'translation.description',
            type: STRING,
            formElement: FORM_TEXTAREA,
            order: 3,
            validation: {
                required: true
            }
        },
        locale: {
            title: DISEASE_SECTIONS_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        },
        diseases: {
            title: DISEASE_SECTIONS_DISEASES,
            placeholder: 'adminPanel.dictionaries.diseaseSection.diseases',
            objectPath: 'diseases',
            type: DICTIONARY_SET,
            optionsSource: 'diseases/',
            order: 4
        }
    }
}