import React from 'react';

/**
 * Раздел на странице просмотра породы
 * Внутри раздела может быть несколько секций
 * @see BreedInfoSection
 */
const BreedInfoBlock = ({children}) => {
    return (
        <div className="breed-info-block__wrapper">
            {children}
        </div>
    );
};

export default BreedInfoBlock;