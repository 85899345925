import React from 'react';
import {hasValue, isEmptyOrNull} from "../../../app/helper/commonHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

/**
 * Заголовок справочника с возможностью вернуться к определенному URL
 * @param mainInscription основной заголовок страницы
 * @param topInscription верхний заголовок - текст или ссылка на страницу с полным списком записей справочника.
 * Объект: topInscription: {title, allowLinkForAllUsers, path}
 * @param allowMoveToRecordsSetForAllUsers разрешить переход к списку записей справочника для всех пользователей
 * @param showEditAction показать ссылку на страницу с редактированием элемента справочника.
 * Объект: showEditAction={doShow, path}
 * @param dictionaryItem элемент справочника
 */
const DictionaryHeader = ({
                              mainInscription,
                              topInscription = null,
                              showEditAction = null,
                              dictionaryItem = null
                          }) => {
    const navigate = useNavigate();
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    function isAdminOrModerator() {
        return isAdmin || isModerator;
    }

    function getTopInscription() {
        if (isEmptyOrNull(topInscription) || isEmptyOrNull(topInscription.title)) {
            return null;
        }

        //показываем ссылку если она заполнена, админам или если разрешено для всех пользователей
        if (hasValue(topInscription.path) &&
            (isAdminOrModerator() ||
                (hasValue(topInscription.allowLinkForAllUsers) && topInscription.allowLinkForAllUsers === true))) {

            return <div className="dictionary-title">
                <span>
                    <Link to={`/${topInscription.path}`}>{topInscription.title}</Link>
                </span>
            </div>
        }

        //во всех остальных случаях показываем текст
        return <div className="dictionary-title">
            <span>{topInscription.title}</span>
        </div>
    }

    const goBack = () => {
        navigate(-1); // -1 позволяет вернуться на предыдущую страницу
    };
    return (
        <div className="dictionary-element-header__wrapper">
            <div className="dictionary-element-header">
                {getTopInscription()}
                <FontAwesomeIcon icon={faArrowLeft} className="fa-arrow-left" onClick={goBack}/>


                <span>{mainInscription}</span>

                {/*Ссылка на страницу с редактированием*/}
                {
                    isAdminOrModerator() && hasValue(showEditAction) && showEditAction.doShow &&
                    hasValue(showEditAction.path) && hasValue(dictionaryItem) && hasValue(dictionaryItem.id) ?
                        <FontAwesomeIcon icon={faPen} className="fa-dictionary-edit"
                                         onClick={() =>
                                             navigate(`/${showEditAction.path}/${dictionaryItem.id}/edit`,
                                                 {state: {dictionaryEditingItem: dictionaryItem}})}/> : null}
            </div>
        </div>
    );
};

export default DictionaryHeader;