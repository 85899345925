import {
    DICTIONARY,
    ENUM,
    ENUM_SET,
    FORM_CHECKBOX_SET,
    FORM_INPUT,
    FORM_RADIO_SET,
    FORM_SINGLE_OPTION,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const BREED_DISEASE_ID = "breedDiseaseId";
export const BREED_DISEASE_TRANSLATION_ID = "breedDiseaseTranslationId";
export const BREED_DISEASE_CODE = "breedDiseaseCode";
export const BREED_DISEASE_NAME = "breedDiseaseName";
export const BREED_DISEASE_DESCRIPTION = "breedDiseaseDescription";
export const BREED_DISEASE_LOCALE = "breedDiseaseLocale";
export const BREED_DISEASE_DISEASE = "breedDiseaseDisease";
export const BREED_DISEASE_BREED = "breedDiseaseBreed";
export const BREED_DISEASE_CAUSES = "breedDiseaseOccurrenceCauses";
export const BREED_DISEASE_AGES_OF_ONSET = "breedDiseaseAgesOfOnset";
export const BREED_DISEASE_PREVALENCE = "breedDiseasePrevalence";

export const BREED_DISEASE_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.breedDisease.title',
    backControllerName: 'breed-diseases',
    titlePrepositionalCase: 'adminPanel.dictionaries.breedDisease.titlePrepositionalCase',
    isAllElementsPageAvailable: false,
    fields: {
        id: {
            title: BREED_DISEASE_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: BREED_DISEASE_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: BREED_DISEASE_NAME,
            placeholder: 'adminPanel.dictionaries.breedDisease.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: BREED_DISEASE_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'BREED_DISEASE_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        breed: {
            title: BREED_DISEASE_BREED,
            placeholder: 'adminPanel.dictionaries.breedDisease.breed',
            objectPath: 'breed',
            type: DICTIONARY,
            formElement: FORM_SINGLE_OPTION,
            optionsSource: 'breeds/',
            order: 3
        },
        disease: {
            title: BREED_DISEASE_DISEASE,
            placeholder: 'adminPanel.dictionaries.breedDisease.disease',
            objectPath: 'disease',
            type: DICTIONARY,
            formElement: FORM_SINGLE_OPTION,
            optionsSource: 'diseases/',
            order: 4
        },
        occurrenceCauses: {
            title: BREED_DISEASE_CAUSES,
            placeholder: 'adminPanel.dictionaries.breedDisease.causes',
            objectPath: 'occurrenceCauses',
            type: ENUM_SET,
            formElement: FORM_CHECKBOX_SET,
            optionsSource: 'breed-diseases/cause-options/',
            order: 5
        },
        averageAgesOfOnset: {
            title: BREED_DISEASE_AGES_OF_ONSET,
            placeholder: 'adminPanel.dictionaries.breedDisease.agesOfOnset',
            objectPath: 'averageAgesOfOnset',
            type: ENUM_SET,
            formElement: FORM_CHECKBOX_SET,
            optionsSource: 'breed-diseases/animal-age-categories/',
            order: 6
        },
        prevalence: {
            title: BREED_DISEASE_PREVALENCE,
            placeholder: 'adminPanel.dictionaries.breedDisease.prevalence',
            objectPath: 'prevalence',
            type: ENUM,
            formElement: FORM_RADIO_SET,
            optionsSource: 'breed-diseases/occurrence-frequencies/',
            order: 7
        },
        locale: {
            title: BREED_DISEASE_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        }
    }
}