/**
 * Общие проверки
 */

//const trueFirst = [...users].sort((a, b) => b.boolProp - a.boolProp);

import {BREED, MAIN, MESSAGES} from "../../components/common/menu/menulinks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faPaw} from "@fortawesome/free-solid-svg-icons/faPaw";
import {DEFAULT_PAGE_LIMIT} from "../const/appConst";

/**
 * Проверяет наличие значения
 * Для коллекций проверяет наличие элементов
 */
export function hasValue(value) {
    return !isEmptyOrNull(value)
}

/**
 * Пустое значение или null
 * Для коллекций проверяет отсутствие элементов
 */
export function isEmptyOrNull(value) {
    if (value === undefined || value === 'undefined' || value === null) {
        return true;
    }

    if (Array.isArray(value) && value.length === 0) {
        return true;
    }

    if (isEmptyObject(value)) {
        return true;
    }

    if (typeof value === 'string' && isEmptyString(value)) {
        return true;
    }
    return false;
}

/**
 * Инверсия функции isPersisted
 */
export function isNotPersisted(value) {
    return !isPersisted(value);
}

/**
 * Передается идентификатор.
 * Если значение =0, то сущность не сохранялась в БД
 */
export function isPersisted(value) {
    if (value === undefined || value === 'undefined' || value === null || value === 0) {
        return false;
    }
    return true;
}


/**
 * Рассчитать количество страниц для пагинации по общему количеству элементов
 */
export function getPageCount(countOfElements) {
    if (countOfElements === 0) {
        return 0;
    }
    return countOfElements / DEFAULT_PAGE_LIMIT + 1;
}

function isEmptyString(value) {
    return !value || value.trim().length === 0;
}

/**
 * Является ли value типом Object
 */
function isEmptyObject(value) {
    return value && Object.keys(value).length === 0 && value.constructor === Object;
}

export function getIconByAlias(alias) {
    switch (alias) {
        case MAIN:
            return (<FontAwesomeIcon icon={faHome} className="fa-2x"/>)
        case MESSAGES:
            return (<FontAwesomeIcon icon={faEnvelope} className="fa-2x"/>)
        case BREED:
            return (<FontAwesomeIcon icon={faPaw} className="fa-2x"/>)
        default:
            return (<></>);
    }
}
