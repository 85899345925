import React from 'react';
import {useSelector} from "react-redux";
import TableCell from "../../../table/simple/cells/TableCell";
import TableLine from "../../../table/simple/line/TableLine";
import {Route, Routes, useNavigate} from "react-router-dom";
import DictionaryElementView from "../../viewitem/DictionaryElementView";
import DeleteItemTableCell from "../../../table/simple/cells/DeleteItemTableCell";

/**
 * Список элементов справочника
 * @param data данные справочника
 * @param metadata описание справочника
 */
const DictionaryTableLines = ({data, metadata}) => {
    const navigate = useNavigate();
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    /**
     * Показывать ли элементы управления над элементами справочника
     */
    function showActions() {
        return isAdmin || isModerator;
    }

    return <>
        {[...data].sort((a, b) => a.name.localeCompare(b.name)).map(dictionaryItem => {
            return (
                <TableLine key={dictionaryItem.id} editable={true}>
                    <TableCell cellSize={`${showActions() ? '90' : '100'}`}
                               onClick={() => navigate(`/${metadata.backControllerName}/${dictionaryItem.id}`)}>
                        <span>{dictionaryItem.name}</span>
                    </TableCell>
                    {showActions() ? <DeleteItemTableCell id={dictionaryItem.id} title={dictionaryItem.name}
                                                          metadata={metadata}/> : null}

                </TableLine>
            );
        })}
        <Routes>
            <Route path={`${metadata.backControllerName}/:id`}
                   element={<DictionaryElementView metadata={metadata}/>}/>
        </Routes>
    </>

};

export default DictionaryTableLines;