import {
    formBreedSchema,
    formDto,
    getAnimalClubsValues,
    getEntityNextId,
    getEnumsValues,
    getStoredFacts,
    getStoredSources,
    initFacts,
    initSourceLinks
} from "../helpers/breedHelper";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useRef, useState} from "react";
import {
    ACTION_ADD,
    ACTION_UPDATE,
    DICTIONARY_SET,
    ENUM_SET
} from "../../../components/common/form/helper/formConstants";
import {CAT, EMPTY} from "../../../app/const/appConst";
import {getLanguageShortName} from "../../../utils/langUtils";
import {BREED_FACTS, BREED_METADATA, SOURCES} from "../helpers/breedFormConstants";
import {isEmptyOrNull} from "../../../app/helper/commonHelper";
import {get} from "lodash";
import {
    clearSessionDataForEntity,
    getDefaultValueForType,
    getStoredItem
} from "../../../components/common/form/helper/formHelper";
import BreedService from "../api/BreedService";
import {useForm} from "react-hook-form";
import {getPossibleValues} from "../../../service/dictionaryService";
import {COUNTRY_METADATA} from "../../adminpanel/dictionaries/metadata/countryMetadata";

function useBreedForm(action, breed, image, token, t, i18n, navigate) {
    const {register, formState: {errors}, handleSubmit, watch, setValue, getValues} = useForm({
        resolver: yupResolver(formBreedSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    });
    const allValues = watch();
    const [animalKind, setAnimalKind] = useState(action === ACTION_ADD ? CAT : breed.animalKind);
    const language = getLanguageShortName(i18n.language);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [updateImage, setUpdateImage] = useState(false);
    const [sourceLinks, setSourceLinks] = useState([]);
    const [breedFacts, setBreedFacts] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [countries, setCountries] = useState([]);
    const [breedEnums, setBreedEnums] = useState([]);
    const [animalClubs, setAnimalClubs] = useState([]);
    const sourceIds = useRef(action === ACTION_ADD ? getEntityNextId(sourceLinks) : 0);
    const factsIds = useRef(action === ACTION_ADD ? getEntityNextId(breedFacts) : 0);

    /**
     * Асинхронное получение данных с бэка
     */
    useEffect(() => {
        //получаем countries
        getCountriesOptions(setErrorMessage, setCountries, token);
        //получаем все значения Enum'ов
        getEnumsValues(setErrorMessage, setBreedEnums, token);
        //получаем список клубов животных
        getAnimalClubsValues(setErrorMessage, setAnimalClubs)
    }, [token]);

    useEffect(() => {
        if (isEmptyOrNull(countries) || isEmptyOrNull(breedEnums)) {
            return;
        }
        switch (action) {
            case ACTION_ADD :
                return initAdd();
            case ACTION_UPDATE :
                return initUpdate(breed);
            default:
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, breedEnums]);

    function getCountriesOptions(setErrorMessage, setCountries, token) {
        const response = getPossibleValues(COUNTRY_METADATA.backControllerName, token);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setCountries(resp.data.data);
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message);
        });
    }

    /**
     * Инит полей формы при добавлении породы
     * просечивает данные из стора для простых полей (не массивов)
     */
    const initAdd = () => {
        Object.entries(BREED_METADATA.fields).forEach(field => {
            setValue(field[1].title, getStoredItem(field[1].title, field[1].type))
        })

        setBreedFacts(getStoredFacts(setValue));
        setSourceLinks(getStoredSources(setValue))
    };

    /**
     * Инит полей формы при обновлении породы
     * просечивает данные из БД
     */
    const initUpdate = (breedInfo) => {
        if (isEmptyOrNull(breedInfo)) {
            return;
        }
        Object.entries(BREED_METADATA.fields).forEach(field => {
            if (ENUM_SET === field[1].type) {
                const setOfElements = new Set(get(breedInfo, field[1].objectPath, {}))
                setOfElements.forEach(el => {
                    setValue(`${field[1].title}.${el.id}`, el.id)
                })
            } else if (DICTIONARY_SET === field[1].type) {
                const setOfElements = new Set(get(breedInfo, field[1].objectPath, {}))
                if (isEmptyOrNull(setOfElements)) {
                    return;
                }
                //Зачищаем поле. Если этого не сделать, то откуда-то появляется [empty, false...]
                setValue(field[1].title, null);

                // Просечиваем значения в поле
                setOfElements.forEach(el => {
                    setValue(`${field[1].title}.${el.code}`, el.id)
                })
            } else {
                setValue(field[1].title, get(breedInfo, field[1].objectPath, getDefaultValueForType(field[1].type)));
            }
        })
        //заполнение коллекций
        setValue(SOURCES, breedInfo.translation.sources)
        setValue(BREED_FACTS, breedInfo.translation.breedFacts)

        initFacts(factsIds, setBreedFacts, breedInfo.translation.breedFacts)
        initSourceLinks(sourceIds, setSourceLinks, breedInfo.translation.sources);
        if (image.data_url !== EMPTY) {
            setImages(Array.of(image))
        }
    };

    const submitForm = (data) => {
        setDisabledSubmit(true);
        setIsLoading(true);

        const dto = formDto(data, language, animalKind);
        if (!updateImage && ACTION_UPDATE === action) {
            dto.filename = breed.filename;
        }

        const resp = BreedService.saveBreed(dto, updateImage, images.length === 0 ? null : images[0].file, token);
        resp.then(
            (resp) => {

                clearSessionDataForEntity(Object.entries(BREED_METADATA.fields).map(field => field[1]), action);
                setError(false);
                setIsLoading(false);
                navigate(`/breeds/${resp.data.data.breedId}`);
            },
            (error) => {
                setIsLoading(false);
                setError(true);
                setDisabledSubmit(false);
                setErrorMessage(error.response?.data?.messages?.ERROR[0] || error.message);
            }
        );
    };

    return {
        register,
        errors,
        handleSubmit,
        allValues,
        setValue,
        getValues,
        animalKind,
        setAnimalKind,
        error,
        errorMessage,
        loading,
        images,
        setImages,
        setUpdateImage,
        submitForm,
        disabledSubmit,
        countries,
        breedEnums,
        animalClubs,
        sourceLinks,
        setSourceLinks,
        breedFacts,
        setBreedFacts
    }
}

export default useBreedForm;