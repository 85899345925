import React, {useState} from 'react';
import {EMPTY} from "../../../../app/const/appConst";
import Arrow from "../../../../components/Arrow";

/**
 * Секция в разделе на странице просмотра породы.
 * Является элементом внутри
 * @see BreedInfoBlock
 * Следует использовать когда нужно внутри блока уместить несколько
 * логически разделенных элементов.
 * Информация внутри BreedInfoSection по умолчанию свёрнута
 */
const BreedInfoSection = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className='breed-info__section'>
            <div className="breed-info__section-title" onClick={() => toggleOpen()}>
                <span>{title}</span>
                <Arrow isActive={isOpen}/>
            </div>
            <div className={`breed-info__section-body ${isOpen ? 'visible' : EMPTY}`}>
                {children}
            </div>

        </div>
    );
};

export default BreedInfoSection;