import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Footer = () => {
    const {t} = useTranslation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    /**
     * Для мобильной версии футер не выводим
     */
    if (isMobile) {
        return null;
    }
    return (
        <footer>
            <div className="footer-gradient"></div>
            <div className="copyright-info">
                <div>
                    <span>© {t("milkins")} 2025</span>
                </div>
                {/*<div>*/}
                {/*    <Link to="/" className="copyright-info__link">{t("footer.feedback")}</Link>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Link to="/" className="copyright-info__link">{t("footer.termsAndCondition")}</Link>*/}
                {/*</div>*/}
            </div>
        </footer>
    );
};

export default Footer;