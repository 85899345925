import {
    BOOLEAN,
    DICTIONARY_SET,
    FORM_CHECKBOX_SET,
    FORM_INPUT, FORM_RADIO_SET,
    FORM_TEXTAREA,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const DISEASE_ID = "diseaseId";
export const DISEASE_TRANSLATION_ID = "diseaseTranslationId";
export const DISEASE_CODE = "diseaseCode";
export const DISEASE_NAME = "diseaseName";
export const DISEASE_FULL_NAME = "diseaseFullName";
export const DISEASE_TRANSMITTED_FOR_HUMANS = "diseaseTransmittedForHumans";
export const DISEASE_DESCRIPTION = "diseaseDescription";
export const DISEASE_LOCALE = "diseaseLocale";
export const DISEASE_SECTIONS = "diseaseSections";

export const DISEASE_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.disease.title',
    backControllerName: 'diseases',
    titlePrepositionalCase: 'adminPanel.dictionaries.disease.titlePrepositionalCase',
    isAllElementsPageAvailable: true,
    fields: {
        id: {
            title: DISEASE_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: DISEASE_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: DISEASE_NAME,
            placeholder: 'adminPanel.dictionaries.disease.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: DISEASE_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'DISEASE_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        fullName: {
            title: DISEASE_FULL_NAME,
            placeholder: 'adminPanel.dictionaries.disease.fullName',
            objectPath: 'translation.fullName',
            type: STRING,
            formElement: FORM_INPUT,
            order: 3,
            validation: {
                required: true
            }
        },
        transmittedForHumans: {
            title: DISEASE_TRANSMITTED_FOR_HUMANS,
            placeholder: 'adminPanel.dictionaries.disease.transmittedForHumans',
            objectPath: 'transmittedForHumans',
            type: BOOLEAN,
            formElement: FORM_RADIO_SET,
            order: 4,
        },
        description: {
            title: DISEASE_DESCRIPTION,
            placeholder: 'adminPanel.dictionaries.disease.description',
            objectPath: 'translation.description',
            type: STRING,
            formElement: FORM_TEXTAREA,
            order: 5
        },
        locale: {
            title: DISEASE_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        },
        sections: {
            title: DISEASE_SECTIONS,
            placeholder: 'adminPanel.dictionaries.disease.sections',
            objectPath: 'sections',
            type: DICTIONARY_SET,
            formElement: FORM_CHECKBOX_SET,
            optionsSource: 'disease-sections/',
            order: 6
        }
    }
}