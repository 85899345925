import axios from "axios";
import {LOCAL} from "../../../app/const/main";


export default class BreedService {

    /**
     * Получить данные по всем породам конкретного вида животных
     */
    static async getByAnimalKind(animalKind, lang) {
        const config = {
            headers: {'Language': lang},
            params: {animalKind: animalKind}
        }
        return await axios.get(`${LOCAL}/breeds/by-animal-kind`, config);
    }

    /**
     * Получить название пород конкретного вида животных по примеру породы (для определения типа животного)
     */
    static async getAnimalKindAllBreedsByExample(breedId, lang) {
        const config = {
            headers: {'Language': lang}
        }
        return await axios.get(`${LOCAL}/breeds/by-kind/example/${breedId}`, config);
    }

    static async saveBreed(data, updateImage, image, token) {
        const form = new FormData();
        const breedDtoBlob = new Blob([JSON.stringify(data)], {
            type: 'application/json'
        });

        form.append("breedDto", breedDtoBlob)
        form.append("image", image)
        form.append("updateImage", updateImage)
        return await axios.post(`${LOCAL}/breeds/new`, form,
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Отдельный запрос - ТОЛЬКО для страницы просмотра сведений о породе
     * Нужен в связи с тем, что здесь используется специфичная Dto.
     */
    static async showBreed(id) {
        return await axios.get(`${LOCAL}/breeds/show/${id}`);
    }

    /**
     * Получить локализованные сведения о породе по Id
     */
    static async getBreedDataById(id) {
        return await axios.get(`${LOCAL}/breeds/${id}`);
    }

    /**
     * Удалить сведения о породе по Id
     */
    static async remove(id, token) {
        const config = {
            headers: {
                'Authorization': 'Bearer_' + token
            }
        }
        return await axios.delete(`${LOCAL}/breeds/${id}/delete`, config);
    }

    /**
     * Получить изображение породы по CODE породы
     */
    static async getImageByBreedCode(code) {
        return await axios.get(`${LOCAL}/breeds/image`, {params: {code: code}, responseType: 'blob'});
    }
}