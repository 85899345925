import React from 'react';
import InformationBlockHeader from "./InformationBlockHeader";
import InformationBlockItem from "./InformationBlockItem";
import AddElement from "../../../../../pages/pets/viewpet/components/AddElement";
import InformationBlockContent from "./InformationBlockContent";
import Spinner from "../../../Spinner";
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {EMPTY} from "../../../../../app/const/appConst";

/**
 * Информационный блок, состоящий из заголовка и произвольного количества
 * строк - элементов
 */
const InformationBlock = ({title, infoMap, withBorders = true}) => {
    return infoMap === undefined ?
        <Spinner/> :
        <div className={`information-block-content ${withBorders ? EMPTY : 'no-borders'}`}>
            {isEmptyOrNull(title) ? null : <InformationBlockHeader title={title}/>}
            <InformationBlockContent>
                {[...infoMap].sort((a, b) => b.hasValue - a.hasValue)
                    .map((el, index) => el.hasValue ?
                        <InformationBlockItem title={el.title} info={el.value} key={index} disabled={el.disabled}
                                              onClickPath={el.onClickPath} description={el.description}/> :
                        <AddElement title={el.title} key={el.title} onClickPath={el.onClickPath}/>)
                }
            </InformationBlockContent>
        </div>
};

export default InformationBlock;