import {
    DICTIONARY_SET,
    ENUM,
    ENUM_SET,
    FORM_CHECKBOX_SET,
    FORM_INPUT,
    FORM_NUMBER_INPUT,
    FORM_RADIO_SET,
    FORM_TEXTAREA,
    HIDDEN,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";

export const ANIMAL_CLUB_ID = "breedClubId";
export const ANIMAL_CLUB_TRANSLATION_ID = "breedClubTranslationId";
export const ANIMAL_CLUB_CODE = "breedClubCode";
export const ANIMAL_CLUB_ANIMAL_KIND = "breedClubAnimalKind";
export const ANIMAL_CLUB_FOUNDING_YEAR = "breedClubFoundingYear";
export const ANIMAL_CLUB_CLUB_FUNCTIONS = "breedClubClubFunctions";
export const ANIMAL_CLUB_BREEDS = "breedClubBreeds";
export const ANIMAL_CLUB_NAME = "breedClubName";
export const ANIMAL_CLUB_FULL_NAME = "breedClubFullName";
export const ANIMAL_CLUB_DESCRIPTION = "breedClubDescription";
export const ANIMAL_CLUB_LOCALE = "breedClubLocale";

/**
 * TODO: добавить placeholder и подробное описание
 */
export const ANIMAL_CLUBS_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.animalClub.title',
    backControllerName: 'animal-clubs',
    titlePrepositionalCase: 'adminPanel.dictionaries.animalClub.titlePrepositionalCase',
    fields: {
        id: {
            title: ANIMAL_CLUB_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: ANIMAL_CLUB_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        code: {
            title: ANIMAL_CLUB_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'ANIMAL_CLUB_',
            validation: {
                required: true
            },
            visibleField: false,
        },
        name: {
            title: ANIMAL_CLUB_NAME,
            placeholder: 'adminPanel.dictionaries.animalClub.abbr',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        fullName: {
            title: ANIMAL_CLUB_FULL_NAME,
            placeholder: 'adminPanel.dictionaries.animalClub.fullName',
            objectPath: 'translation.fullName',
            type: STRING,
            formElement: FORM_INPUT,
            order: 3,
            validation: {
                required: true
            }
        },
        description: {
            title: ANIMAL_CLUB_DESCRIPTION,
            placeholder: 'adminPanel.dictionaries.animalClub.description',
            objectPath: 'translation.description',
            type: STRING,
            formElement: FORM_TEXTAREA,
            order: 6,
            validation: {
                required: true
            }
        },
        locale: {
            title: ANIMAL_CLUB_LOCALE,
            objectPath: 'translation.locale',
            type: STRING,
            visibleField: false
        },
        animalKind: {
            title: ANIMAL_CLUB_ANIMAL_KIND,
            placeholder: 'adminPanel.dictionaries.animalClub.animalKind',
            objectPath: 'animalKind',
            type: ENUM,
            formElement: FORM_RADIO_SET,
            optionsSource: 'breeds/animal-kind-dtos',
            order: 4,
            validation: {
                required: true,
                minValue: 2
            }
        },
        foundingYear: {
            title: ANIMAL_CLUB_FOUNDING_YEAR,
            placeholder: 'adminPanel.dictionaries.animalClub.foundingYear',
            objectPath: 'foundingYear',
            type: NUMBER,
            formElement: FORM_NUMBER_INPUT,
            order: 5
        },
        clubFunctions: {
            title: ANIMAL_CLUB_CLUB_FUNCTIONS,
            placeholder: 'adminPanel.dictionaries.animalClub.clubFunctions',
            objectPath: 'clubFunctions',
            type: ENUM_SET,
            formElement: FORM_CHECKBOX_SET,
            optionsSource: 'animal-clubs/club-functions',
            order: 7
        },
        breeds: {
            title: ANIMAL_CLUB_BREEDS,
            placeholder: 'adminPanel.dictionaries.animalClub.breeds',
            objectPath: 'breeds',
            type: DICTIONARY_SET,
            optionsSource: 'breeds/',
            order: 8
        }
    }
}