import React from 'react';
import SimpleTable from "../../../table/simple/SimpleTable";
import DictionaryTableTitle from "./DictionaryTableTitle";
import DictionaryTableLines from "./DictionaryTableLines";

/**
 * Таблица со списком элементов справочника
 * @param data элементы справочника
 * @param metadata описание справочника
 */
const DictionaryTable = ({data, metadata}) => {
    return (
        <SimpleTable>
            <DictionaryTableTitle/>
            <DictionaryTableLines data={data} metadata={metadata}/>
        </SimpleTable>
    );
};

export default DictionaryTable;