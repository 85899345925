import React, {useEffect, useState} from 'react';
import BreedsTable from "./components/breedstable/BreedsTable";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PageTitle from "../../components/common/PageTitle";
import SimpleFilter from "../../components/common/ui/filter/SimpleFilter";
import AnimalKindSelector from "./components/AnimalKindSelector";
import {useBreeds} from "./hooks/useBreeds";
import BreedService from "./api/BreedService";
import Spinner from "../../components/common/Spinner";
import {useTranslation} from "react-i18next";
import Content from "../../components/common/Content";
import {ANIMAL_KIND_STORED_ITEM, CAT, EMPTY} from "../../app/const/appConst";
import FormItem from "../../components/common/form/parts/inputs/wrappers/FormItem";
import {useSelector} from "react-redux";
import {getStoredItem, storeItem} from "../../components/common/form/helper/formHelper";
import {STRING} from "../../components/common/form/helper/formConstants";

/**
 * Страница со списком пород животных
 *
 * При переходе на страницу Breeds должен происходить запрос
 * List<Breeds> getBreedsByType(String animalType) который возвращает список пород
 * конкретного типа животных
 *
 * useMemo(callback, deps) - производит вычисления, запоминает их и кеширует - мемоизация
 * deps - зависимости. Какие-то переменные, поля объекта
 *  каждый раз, когда зависимости меняются функция вновь пересчитает
 * callback -- должен возвращать результат вычислений

 * отсортированный массив отфильтрованный по условию
 *
 **/
const Breeds = () => {
    const {t, i18n} = useTranslation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);

    const [breedsInfo, setBreedsInfo] = useState([]);
    const [filter, setFilter] = useState({sort: 'title', query: EMPTY})
    const [animalKind, setAnimalKind] = useState(EMPTY)
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);

    const sortedAndSearchedBreeds = useBreeds(breedsInfo, filter.sort, filter.query, animalKind)

    const sortOptions = [
        {value: 'title', name: t("sort.byTitle")},
        {value: 'origin', name: t("sort.byOrigin")}
    ];

    /**
     * Получаем сохраненное в стор значение ANIMAL KIND или выставляем CAT по умолчанию
     */
    function getAnimalKind() {
        const storedAnimalKind = getStoredItem(ANIMAL_KIND_STORED_ITEM, STRING);
        if (storedAnimalKind === EMPTY) {
            storeItem(ANIMAL_KIND_STORED_ITEM, CAT);
            setAnimalKind(CAT);
            return CAT;
        }
        setAnimalKind(storedAnimalKind);
        return storedAnimalKind;
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        const initialAnimalKind = getAnimalKind();
        const response = BreedService.getByAnimalKind(initialAnimalKind, i18n.language);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setBreedsInfo(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });

    }, [animalKind, i18n.language])
    return (
        <Content>
            <Breadcrumbs elements={[{page: t("page.breeds.title"), path: ''}]}/>
            <PageTitle title={t("page.breeds.title")}/>

            <FormItem>
                <AnimalKindSelector animalKind={animalKind} setAnimalKind={setAnimalKind} disabled={loading}/>
            </FormItem>

            {isMobile ? null :
                <SimpleFilter sortOptions={sortOptions} filter={filter} setFilter={setFilter}/>}
            {loading ?
                <Spinner/> :
                <BreedsTable breedsInfo={sortedAndSearchedBreeds} error={errorMessage}/>
            }
        </Content>
    );
};

export default Breeds;