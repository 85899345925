import {useMemo} from 'react';
import {EMPTY} from "../../../app/const/appConst";

// кастомные пользовательские хуки - это хуки, которые внутри себя
// используют стандартные хуки реакта
//сортировка постов по переданному параметру sort
export const useSortedBreeds = (breedsInfo, sort) => {

    return useMemo(() => {
        if (breedsInfo[sort] === null) {
            return breedsInfo;
        }
        if (sort) {
            return [...breedsInfo].sort((a, b) => {
                const aValue = a[sort] || EMPTY;
                const bValue = b[sort] || EMPTY;
                return aValue.localeCompare(bValue);
            });
        }
        return breedsInfo;
    }, [breedsInfo, sort]);
}

//сортировка и фильтрация по названию и типу животного
export const useBreeds = (breedsInfo, sort, query, animalKind) => {
    const sortedBreeds = useSortedBreeds(breedsInfo, sort);

    // sorted And Searched Breeds
    return useMemo(() => {
        if (query && query.length > 1) {
            return sortedBreeds.filter(breed => breed.title.toLowerCase().includes(query.toLowerCase()));
        }
        return sortedBreeds;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, sortedBreeds, animalKind]);
}

