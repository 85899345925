import React, {useEffect, useRef, useState} from 'react';
import {EMPTY} from "../../../../../../app/const/appConst";
import {LABEL_CLASSNAME_WITHOUT_VALUE} from "../../../helper/commonFormConstants";
import {hasValue, isEmptyOrNull} from "../../../../../../app/helper/commonHelper";
import {movePlaceholderDown, movePlaceholderUp, removeItem, storeItem} from "../../../helper/formHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import FormItem from "./FormItem";
import FormError from "./FormError";
import {useFilteredItemsForInputSingleOptions} from "../../../hooks/useFilteredItemsForInputSingleOptions";
import {ACTION_UPDATE} from "../../../helper/formConstants";

/**
 * Компонент формы "Текстовый инпут с выпадающим списком опций"
 * с возможностью выбрать только один элемент
 *
 * Реализация заточена под DictionaryDto
 */
const TextInputSingleOptionFormItem = ({
                                           action, fieldName, fieldOrder, register, options, setValue, getValues, placeholder,
                                           disabled = false, errors = null,
                                           isNeedToStore = true
                                       }) => {
    const inputRef = useRef(EMPTY);
    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE);
    const [emptyResult, setEmptyResult] = useState(false)
    // значение, которое отображается в инпуте
    const [inputValue, setInputValue] = useState(EMPTY);
    // начальное состояние поля при работе с обновлением элемента формы
    const [initialValueFromUpdate, setInitialValueFromUpdate] = useState(EMPTY);
    //компонент отображения списка выбора
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    let selectedValue = getValues(fieldName);

    // фильтруем элементы
    const filteredItems = useFilteredItemsForInputSingleOptions(options, inputRef.current.value, setEmptyResult)

    /**
     * Изначальное просечивание значения в инпут
     */
    useEffect(() => {
        // Сначала пробуем просетить значение когда action = UPDATE (редактирование элемента формы)
        if (action === ACTION_UPDATE) {
            let valueFromEditableForm = getValues(fieldName);
            if (hasValue(valueFromEditableForm)) {
                setInitialValueFromUpdate(valueFromEditableForm);
            }
            if (hasValue(initialValueFromUpdate) && hasValue(options)) {
                setInitialValueIntoInput(initialValueFromUpdate);
                return;
            }
        }

        //затем пробуем просетить пустое значение если никакая опция не была выбрана
        if (isEmptyOrNull(selectedValue) || isEmptyOrNull(options) || options.length === 0) {
            //просечиваем пустое значение
            setInputValue(EMPTY);
            setValue(fieldName, EMPTY)
            return;
        }
        // в конце просечиваем выбранное значение
        setInitialValueIntoInput(selectedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    function setInitialValueIntoInput(value) {
        const option = options.find(el => el.code === value);
        if (hasValue(option)) {
            setInputValue(option.name);
            setValue(fieldName, value)
            inputRef.current.value = option.name;
            movePlaceholder(showDropdown);
        }
    }


    /**
     * Обновить значение в инпуте
     */
    function updateSelectedOption(optionCode, optionTitle) {
        //если поле не заполнено, то зануляем в setValue
        if (isEmptyOrNull(optionCode) || isEmptyOrNull(optionTitle)) {
            setValue(fieldName, EMPTY)
            return
        }
        if (isNeedToStore) {
            storeItem(fieldName, optionCode)
        }
        //просечиваем в управляемый компонент хука формы
        setValue(fieldName, optionCode)
        //просечиваем текст в инпут
        setInputValue(optionTitle);
        setShowDropdown(false);
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события изменения в инпуте
     */
    function handleInputChange(val) {
        setInputValue(val);
        if (!showDropdown) {
            setShowDropdown(true);
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события получения фокуса в инпуте
     */
    function handleInputFocus() {
        if (isEmptyOrNull(inputValue)) {
            setShowDropdown(true)
        }
        movePlaceholder(showDropdown);
    }

    const handleWheel = (event) => {
        const node = dropdownRef.current;
        if (node) {
            const {scrollTop, scrollHeight, clientHeight} = node;
            const atTop = scrollTop === 0;
            const atBottom = scrollTop + clientHeight === scrollHeight;

            if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
                event.preventDefault();
            }
        }
    };

    /**
     * Обработка события нажатия на треугольник
     */
    function handleArrowClick() {
        setShowDropdown(!showDropdown);
        movePlaceholder(!showDropdown);
    }

    /**
     * Обработка потери фокуса инпутом.
     */
    function handleOnBlur() {
        movePlaceholder(showDropdown);
    }

    /**
     * Смещать ли плейсхолдер в инпут при потере фокуса полем
     */
    function movePlaceholder(isDropdownOptionsShow) {
        if (!isDropdownOptionsShow && isEmptyOrNull(inputRef.current.value)) {
            movePlaceholderDown(setLabelClass)
        } else {
            movePlaceholderUp(setLabelClass);
        }
    }

    /**
     * Если значение в инпуте не пустое - отображаем корзину
     */
    function getTrash() {
        if (inputValue !== EMPTY) {
            return <div className="input-with-options__trash-wrapper" onClick={() => clearInputValue()}>
                <FontAwesomeIcon icon={faTrash} size={"xl"}/>
            </div>
        }
        return null;
    }

    /**
     * Очистка значения в инпуте
     */
    function clearInputValue() {
        if (isNeedToStore) {
            removeItem(fieldName);
        }
        setInputValue(EMPTY);
        setValue(fieldName, EMPTY);
        movePlaceholderDown(setLabelClass)
    }

    return (
        <FormItem>
            <div className='input-with-options-wrapper'>
                {/*Корзина для очистки значения в форме*/}
                {getTrash()}
                <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
                {/*Инпут*/}
                <input
                    id={fieldName}
                    type="text"
                    className="form__input"
                    {...register(fieldName)}
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleOnBlur}
                    tabIndex={fieldOrder}
                    disabled={disabled}
                    autoComplete="off"
                />
                {/*Стрелка*/}
                <div className="input-field-arrow__wrapper" onClick={handleArrowClick}>
                    <div className={`input-field-arrow ${showDropdown ? EMPTY : 'collapsed'}`}></div>
                </div>
                {/*выпадающие элементы*/}
                <div className={`dropdown-menu ${showDropdown ? 'show' : EMPTY}`}>
                    <div className="dropdown-menu__content" onWheel={handleWheel} ref={dropdownRef}>
                        {filteredItems.map((option) => (
                            <div key={option.code} className={`dropdown-item ${emptyResult ? 'no-items' : EMPTY}`}
                                 onClick={() => updateSelectedOption(option.code, option.name)}>
                                <span>{option?.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {errors === null ? null : errors[fieldName] ?
                <FormError title={errors[fieldName].message} additionalStyle="margin-30"/> : null}
        </FormItem>
    );
};

export default TextInputSingleOptionFormItem;