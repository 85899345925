import React, {useRef} from 'react';
import AnimalKindSelector from "../AnimalKindSelector";
import {useTranslation} from "react-i18next";
import FormItem from "../../../../components/common/form/parts/inputs/wrappers/FormItem";
import {BREED_METADATA} from "../../helpers/breedFormConstants";
import {getEntityNextId} from "../../helpers/breedHelper";
import {isFieldFilled, storeItem} from "../../../../components/common/form/helper/formHelper";
import FormImage from "../../../../components/common/form/parts/inputs/wrappers/FormImage";
import HiddenInput from "../../../../components/common/form/parts/inputs/HiddenInput";
import {ACTION_ADD, FILES_MAX_NUMBER} from "../../../../components/common/form/helper/formConstants";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import Form from "../../../../components/common/form/abstract/Form";
import SubmitSection from "../../../../components/common/form/parts/controlsection/SubmitSection";
import TextAreaFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextAreaFormItem";
import TextInputFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import {hasValue} from "../../../../app/helper/commonHelper";
import BreedFactsFormElement from "./BreedFactsFormElement";
import BreedSourceLinksFormElement from "./BreedSourceLinksFormElement";
import CheckboxFormItem from "../../../../components/common/form/parts/inputs/wrappers/CheckboxFormItem";
import BreedGenderParams from "./BreedGenderParams";
import useBreedForm from "../../hooks/useBreedForm";
import BreedCareFormSection from "./BreedCareFormSection";
import BreedBasicInfoFormSection from "./BreedBasicInfoFormSection";
import TextInputSingleOptionFormItem
    from "../../../../components/common/form/parts/inputs/wrappers/TextInputSingleOptionFormItem";

/**
 * Форма добавления породы животного
 * action = ADD или UPDATE - чтобы разделить логику форм
 */
const BreedForm = ({action, breed = {}, image = {}}) => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = useSelector(state => state.userReducer.token);

    const {
        register,
        errors,
        handleSubmit,
        allValues,
        setValue,
        getValues,
        animalKind,
        setAnimalKind,
        error,
        errorMessage,
        loading,
        images,
        setImages,
        setUpdateImage,
        submitForm,
        disabledSubmit,
        countries,
        breedEnums,
        animalClubs,
        sourceLinks,
        setSourceLinks,
        breedFacts,
        setBreedFacts
    } = useBreedForm(action, breed, image, token, t, i18n, navigate);

    const sourceIds = useRef(action === ACTION_ADD ? getEntityNextId(sourceLinks) : 0);
    const factsIds = useRef(action === ACTION_ADD ? getEntityNextId(breedFacts) : 0);

    function onImageChange(imageList) {
        setUpdateImage(true);
        setImages(imageList);
    }

    return (
        <Form handleSubmit={handleSubmit} submitForm={submitForm}>
            <HiddenInput fieldName={BREED_METADATA.fields.id.title} register={register}/>
            <HiddenInput fieldName={BREED_METADATA.fields.translationId.title} register={register}/>
            <HiddenInput fieldName={BREED_METADATA.fields.appearanceId.title} register={register}/>
            <HiddenInput fieldName={BREED_METADATA.fields.healthId.title} register={register}/>
            <FormItem>
                <AnimalKindSelector animalKind={animalKind} setAnimalKind={setAnimalKind}
                                    catTitle={t("form.newBreed.cat")} dogTitle={t("form.newBreed.dog")}/>
            </FormItem>

            <TextInputFormItem errors={errors} fieldName={BREED_METADATA.fields.breedTitle.title}
                               placeholder={t("form.newBreed.breedTitle")}
                               register={register}
                               hasValue={hasValue(allValues[BREED_METADATA.fields.breedTitle.title])}
                               tabIndex={BREED_METADATA.fields.breedTitle.tabIndex}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <TextInputFormItem errors={errors} fieldName={BREED_METADATA.fields.code.title} placeholder='C_CODE'
                               register={register} hasValue={hasValue(allValues[BREED_METADATA.fields.code.title])}
                               tabIndex={BREED_METADATA.fields.code.tabIndex}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <TextInputSingleOptionFormItem action={action}
                                           fieldName={BREED_METADATA.fields.countryId.title}
                                           fieldOrder={BREED_METADATA.fields.countryId.tabIndex}
                                           options={countries}
                                           placeholder='Страна происхождения'
                                           register={register}
                                           setValue={setValue} getValues={getValues}/>

            <FormImage images={images} maxNumber={FILES_MAX_NUMBER} onChange={onImageChange}/>

            <BreedBasicInfoFormSection errors={errors} register={register} getValues={getValues}
                                       action={action} basicInfoEnums={breedEnums.basicInfoEnums}
                                       animalClubsOptions={animalClubs} animalKind={animalKind}/>

            <BreedCareFormSection action={action} register={register} getValues={getValues}
                                  careEnums={breedEnums.careEnums}/>

            <BreedGenderParams allValues={allValues} action={action} register={register} errors={errors}/>


            <TextAreaFormItem fieldName={BREED_METADATA.fields.behaviour.title}
                              placeholder={t("form.newBreed.behaviour")}
                              register={register} tabIndex={BREED_METADATA.fields.behaviour.tabIndex} action={action}
                              hasValue={isFieldFilled(getValues, BREED_METADATA.fields.behaviour.title)}/>

            <BreedFactsFormElement factsIds={factsIds} breedFacts={breedFacts} setBreedFacts={setBreedFacts}
                                   action={action} errors={errors} register={register} getValues={getValues}
                                   setValue={setValue}/>

            <BreedSourceLinksFormElement sourceIds={sourceIds} sourceLinks={sourceLinks} setSourceLinks={setSourceLinks}
                                         register={register} errors={errors} action={action} getValues={getValues}
                                         setValue={setValue}/>

            {/*Видимость в списках*/}
            <CheckboxFormItem fieldName={BREED_METADATA.fields.isTechnical.title} register={register}
                              checkboxValue={getValues(BREED_METADATA.fields.isTechnical.title)}
                              putValueInStore={ACTION_ADD === action}
                              title={t("form.newBreed.isTechnical")}
                              tabIndex={BREED_METADATA.fields.isTechnical.tabIndex} errors={errors}
                              additionalStyle='as-table-header'/>

            <SubmitSection hasFormError={error} errorMessage={errorMessage} isFormLoading={loading}
                           submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
        </Form>
    );
};

export default BreedForm;