import React from 'react';
import Arrow from "../../../../Arrow";

/**
 * Заголовок для блока ссылок в мобильном меню
 */
const SectionTitle = ({iconBefore = null, isActive, setActive, title}) => {
    function toggleActiveState() {
        return setActive(!isActive);
    }

    return (
        <div className="mobile-section__title" onClick={() => toggleActiveState()}>
            {iconBefore === null ? null : iconBefore}
            <span>{title}</span>
            <Arrow isActive={isActive}/>
        </div>
    );
};

export default SectionTitle;