import {FORM_INPUT, HIDDEN, NUMBER, STRING} from "../../../../components/common/form/helper/formConstants";

export const CONTINENT_ID = "continentId";
export const CONTINENT_TRANSLATION_ID = "continentTranslationId";
export const CONTINENT_CODE = "continentCode";
export const CONTINENT_NAME = "continentName";
export const CONTINENT_METADATA = {
    dictionaryTitle: 'adminPanel.dictionaries.continents.title',
    backControllerName: 'continents',
    titlePrepositionalCase: 'adminPanel.dictionaries.continents.title',
    isAllElementsPageAvailable: false,
    fields: {
        id: {
            title: CONTINENT_ID,
            objectPath: 'id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        translationId: {
            title: CONTINENT_TRANSLATION_ID,
            objectPath: 'translation.id',
            type: NUMBER,
            formElement: HIDDEN,
            order: 0,
            visibleField: false
        },
        name: {
            title: CONTINENT_NAME,
            placeholder: 'adminPanel.dictionaries.continents.name',
            objectPath: 'translation.title',
            type: STRING,
            formElement: FORM_INPUT,
            order: 1,
            validation: {
                required: true
            }
        },
        code: {
            title: CONTINENT_CODE,
            placeholder: 'form.common.fields.code',
            objectPath: 'code',
            type: STRING,
            formElement: FORM_INPUT,
            order: 2,
            codePrefix: 'CONTINENT_',
            validation: {
                required: true
            },
            visibleField: false
        }
    }
}