import React from 'react';
import SortingSelect from "../select/SortingSelect";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import AddElementLink from "../buttons/AddElementLink";

const SearchBlockWithActions = ({sortOptions, filter, setFilter}) => {
    const {t} = useTranslation();
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    return (
        <div className="search-block-with-actions">
            <SortingSelect
                value={filter.sort}
                onChangeFunction={selectedSort => setFilter({...filter, sort: selectedSort})}
                defaultValue={t("sort.title")}
                options={sortOptions}/>

            {isAdmin || isModerator
                ?
                <div className="search-block-with-actions__actions">
                    <AddElementLink goto='/breeds/new'/>
                </div>
                : null
            }
        </div>
    );
};

export default SearchBlockWithActions;