import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {EMPTY} from "../../../../app/const/appConst";
import {getAllRecordsInCurrentTranslation} from "../../../../service/dictionaryService";
import {Link} from "react-router-dom";
import AddElementLink from "../../ui/buttons/AddElementLink";
import TableNoResults from "../../table/TableNoResults";
import {hasValue, isEmptyOrNull} from "../../../../app/helper/commonHelper";
import DictionaryTable from "./table/DictionaryTable";
import Content from "../../Content";
import Spinner from "../../Spinner";
import DictionaryHeader from "../DictionaryHeader";
import {useSelector} from "react-redux";

/**
 * Основной класс справочников
 * @param metadata - информация о справочнике
 * @param data - элементы справочника
 */
const Dictionary = ({metadata}) => {
    const {t, i18n} = useTranslation();

    const PATH_TO_DICTIONARIES_PAGE = '/panel/dictionaries/';
    const BACK_TO_DICTIONARIES_MSG = 'form.common.phrases.backToDictList';

    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    const [dictionaryData, setDictionaryData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);

    /**
     * Получаем все элементы справочника в виде
     * {id, code, name}
     */
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        setIsLoading(true);
        const response = getAllRecordsInCurrentTranslation(metadata.backControllerName);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setDictionaryData(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language])

    if (hasValue(errorMessage)) {
        return (<Content>
            <TableNoResults title={errorMessage}/>
        </Content>);
    }
    return (
        <Content>
            <DictionaryHeader mainInscription={t(metadata.dictionaryTitle)}/>
            {isAdmin || isModerator ?
                <div className="backward-link-and-add-dict-icon__wrapper">
                    <div className="backward-link">
                        <Link to={PATH_TO_DICTIONARIES_PAGE}
                              className="backref__link">{t(BACK_TO_DICTIONARIES_MSG)}</Link>
                    </div>
                    <div className="add-dict-icon">
                        <AddElementLink goto={`/${metadata.backControllerName}/new`}/>
                    </div>
                </div> : null
            }
            {loading ? <Spinner/> :
                isEmptyOrNull(dictionaryData) ?
                    <TableNoResults title={t("table.empty")}/> :
                    <DictionaryTable data={dictionaryData} metadata={metadata}/>
            }
        </Content>
    );
};

export default Dictionary;